.main-content {
    padding-top: 55px;
    /* Sesuaikan nilai ini dengan tinggi header Anda */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}