@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*>* {
    font-family: Rubik;
    font-size: 10pt;
}
body{
    margin:0;
}